import styled from "styled-components"
import { Link } from "gatsby"

export const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column-reverse;
  background-color: #2c333e;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
  @media screen and (min-width: 550px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

export const FooterNavLinks = styled.ul`
  display: flex;
  font-size: 1.5rem;
  margin-bottom: 5px;
  justify-content:flex-end;
  @media screen and (min-width: 550px) {
    margin-bottom: 0;
  }
`

export const StyledLinkContainer = styled.li`
  list-style-type: none;
  margin-left:24px;
`

export const StyledLink = styled(Link)``
