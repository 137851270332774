import React from "react"
import { FaGithub, FaLinkedin } from "react-icons/fa"
import {
  FooterWrapper,
  FooterNavLinks,
  StyledLinkContainer,
} from "./footer.styles"
import Socials from "../social/socials.component"

const Footer = () => (
  <FooterWrapper>
    <small style={{ color: "white" }}>
      &copy; BayesWorks Ltd. 2014 - {new Date().getFullYear()}. Company No.
      8521904. VAT No. 178 6628 55
    </small>
    <FooterNavLinks>
      <Socials/>
      {/* <StyledLinkContainer>
        <a href="https://www.github.com">
          <FaGithub color="rgb(232,119,34)" />
        </a>
      </StyledLinkContainer>
      <StyledLinkContainer>
        <a href="https://www.twitter.com">
          <FaLinkedin color="rgb(232,119,34)" />
        </a>
      </StyledLinkContainer> */}
    </FooterNavLinks>
  </FooterWrapper>
)

export default Footer
