import React from "react"
import { SocialList } from "./socials.styles"
import { FaGithub, FaLinkedin } from "react-icons/fa"

const Socials = () => (
  <React.Fragment>
    <SocialList>
      <a
        style={{ marginRight: "20px" }}
        href="https://www.github.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaGithub color="rgb(232,119,34)" />
      </a>
      <a
        href="https://www.linkedin.com/company/bayesworks-ltd"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaLinkedin color="rgb(232,119,34)" />
      </a>
    </SocialList>
  </React.Fragment>
)

export default Socials
