import styled from "styled-components"

export const LayoutWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

export const ContentWrapper = styled.main`
  flex-grow: 1;
`
