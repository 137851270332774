import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  body {
    background-color:transparent;
    font: 16px/1.5 "Dosis", sans-serif;
  }

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  h1{
    padding-left: 24px;
    font-size: 64px;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight:600;

    @media (max-width: 768px) {
      font-size:48px;
    }
  }

  h2{
    font-size: 48px;
    font-weight: 600;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    padding-top:24px;
    padding-bottom:24px;
    @media (max-width: 768px) {
      font-size:24px;
    }
  }

  div{
    font-size:16px;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  }
`
