import React, { Component } from "react"
import { Link } from "gatsby"
import {
  HeaderWrapper,
  HeaderNav,
  HeaderNavLinks,
  LogoImage,
  StyledLinkContainer,
  StyledLink
} from "./header.styles"
import Logo from "../../images/bw-logo-only.svg"
import Socials from "../social/socials.component"

class Header extends Component {
  render() {
    return (
      <HeaderWrapper>
        <HeaderNav>
          <Link to="/">
            <LogoImage
              style={{ width: 50, height: 50, marginRight: "24px" }}
              src={Logo}
            ></LogoImage>
          </Link>
          <HeaderNavLinks>
            {/* <StyledLinkContainer>
              <StyledLink to="/contact" hoverColour="#ee7623">
                Contact
              </StyledLink>
            </StyledLinkContainer>
            <StyledLinkContainer>
              <StyledLink to="/about" hoverColour="#ee7623">
                About
              </StyledLink>
            </StyledLinkContainer> */}
          </HeaderNavLinks>        
        </HeaderNav>
        <Socials />
      </HeaderWrapper>
    )
  }
}

export default Header
