import React from "react"
import Footer from "../footer/footer.component"
import Header from "../header/header.component"
import { LayoutWrapper, ContentWrapper } from "../layout/layout.styles"

const Layout = ({ children }) => (
  <React.Fragment>
    <LayoutWrapper>
      <Header />
      <ContentWrapper>
        <div>{children}</div>
      </ContentWrapper>
      <Footer />
    </LayoutWrapper>
  </React.Fragment>
)

export default Layout
