import styled from "styled-components"
import { Link } from "gatsby"

export const HeaderWrapper = styled.header`
  background-color: #ffffff;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: sticky;
  top: 0;
  z-index:999;
  box-shadow: 0 2px rgba(0, 0, 0, 0.1);
  @media screen and (min-width: 550px) {
    width: auto;
    margin-top: 0;
  }
  display:flex;
  justify-content:space-between;
`

export const LogoImage = styled.img``

export const HeaderContainer = styled.div`
  max-width: 100%;
  padding: 0px;
  margin: 0 auto;
`

export const HeaderNav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
`

export const HeaderNavLinks = styled.ul`
  display: flex;
  width: 100%;
  margin-top: 10px;
  list-style: none;

  @media screen and (min-width: 550px) {
    width: auto;
    margin-top: 0;
  }
`

export const StyledLinkContainer = styled.li`
  margin-right: 10px;
`

export const StyledLink = styled(Link)`
  font-size: 16px;
  font-weight:600;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  color: black;
  text-decoration: none;
  display: inline-block;
  //padding: 0.5em 1.5em 0 0;
  border-bottom: 5px solid transparent;

  &.${props => props.activeClassName} {
    border-bottom: 5px solid ${props => props.hoverColour};
    color: #ee7623;
  }

  &:hover {
    border-bottom: 5px solid ${props => props.hoverColour};
  }

  @media screen and (min-width: 550px) {
    padding: 0.5em 1.5em;
  }
`

StyledLink.defaultProps = {
  activeClassName: "active",
}


